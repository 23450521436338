<template>
  <div class="source-mgmt">
    <div class="main-body">
      <el-form size="mini" :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-button type="primary" @click="handleAdd('1')"
            >新增服务资源分组</el-button
          >
          <el-button type="primary" @click="handleAdd('2')"
            >新增服务资源</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="recordList"
        style="width: 100%;margin-bottom: 20px;"
        class="table-custom"
        cell-class-name="text-center"
        row-key="number"
        :header-row-style="headerStyle"
        :tree-props="{ children: 'serviceResourceList', hasChildren: 'true' }"
      >
        <el-table-column prop="number" label="编号"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="path" label="路径"></el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="text" size="mini"
              >编辑</el-button
            >
            <el-button @click="handleDelete(scope.row)" type="text" size="mini"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="
        (opearteType == '1' ? '新增' : '编辑') +
          (objectType == '1' ? '服务资源分组' : '服务资源')
      "
      :visible.sync="dialogTableVisible"
      width="500px"
      :show-close="false"
    >
      <el-form ref="form" :model="editRecord" label-width="80px">
        <el-form-item label="资源分组" v-if="objectType == '2'">
          <el-select
            :popper-append-to-body="false"
            v-model="editRecord.groupNo"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in recordList"
              :key="item.number"
              :label="item.name"
              :value="item.number"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input
            v-model="editRecord.name"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="路径" v-if="objectType == '2'">
          <el-input
            v-model="editRecord.path"
            placeholder="请输入路径"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确定</el-button>
          <el-button @click="closeDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getServiceList,
  addGroupService,
  addService,
  delGroupService,
  delService
} from "@/api/jurisdiction";

export default {
  data() {
    return {
      headerStyle: {
        color: "#333"
      },
      recordList: [],
      dialogTableVisible: false,
      opearteType: "",
      objectType: "",
      parentMenuNo: "",
      editRecord: {}
    };
  },
  mounted() {
    this.queryRecordList();
  },
  methods: {
    /**获取列表 */
    async queryRecordList() {
      const params = {
        findChildren: true
      };
      const res = await getServiceList(params);
      this.recordList = res.ldata;
    },

    handleAdd(type) {
      this.editRecord = {};
      this.opearteType = "1";
      this.objectType = type;
      this.dialogTableVisible = true;
    },

    onSubmit() {
      this.createOrUpdate();
    },

    async createOrUpdate() {
      const api = this.objectType == 1 ? addGroupService : addService;
      const res = await api(this.editRecord);
      this.$message.success("操作成功");
      this.closeDialog();
      this.queryRecordList();
    },

    closeDialog() {
      this.dialogTableVisible = false;
      this.item = {};
    },
    handleEdit(row) {
      this.opearteType = "2";
      this.dialogTableVisible = true;
      this.editRecord = JSON.parse(JSON.stringify(row));
      this.objectType = this.editRecord.groupNo === undefined ? "1" : "2";
    },

    handleDelete(item) {
      this.objectType = item.groupNo === undefined ? "1" : "2";
      this.$confirm("是否确认删除？").then(() => {
        this.delete(item.number);
      });
    },
    async delete(number) {
      const params = {
        number: number
      };
      const api = this.objectType == 1 ? delGroupService : delService;
      const res = await api(params);
      this.$message.success("操作成功");
      this.queryRecordList();
    },
    handleQuery() {
      this.queryRecordList();
    },
    showErrorMessage(message) {
      if (message == "0001") {
        this.$message.warning("登录超时，请重新登录");
      } else if (message == "0004") {
      } else {
        this.$message.error(message);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.main-body {
  .title {
    font-size: 20px;
    padding: 30px 0 40px;
  }

  .list {
    line-height: 30px;
    margin: 20px 30px 10px;

    .name-label {
      float: left;
    }

    .value-label {
      display: inline-block;
      height: 30px;
    }

    .date-global {
      width: 185px;
    }

    .list-input {
      width: 180px;
    }

    .fixed-width-100 {
      width: 100px;
    }

    .fixed-width-200 {
      width: 200px;
    }

    .fixed-width-300 {
      width: 300px;
    }

    .fixed-width-400 {
      width: 400px;
    }
  }

  .table-custom {
    border-bottom: 1px solid #cdcdcd;

    .text-btn {
      width: 50px;
      height: 24px;
      line-height: 24px;
      color: #4949ff;
      display: inline-block;
      background-color: #67aff6;
      cursor: pointer;
    }

    .text-btn.disabled {
      border: #67aff6;
      background-color: #fff;
      cursor: default;
    }

    .fixed-width-75 {
      width: 75px;
    }

    .fixed-width-100 {
      width: 100px;
    }

    .fixed-width-300 {
      width: 300px;
    }

    .mt4 {
      margin-top: 4px;
    }
  }
}
</style>
